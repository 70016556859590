import React, { useState } from "react"
import Image from "gatsby-plugin-sanity-image"
import styled from "styled-components"
import { Link } from "gatsby"
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;

  align-content: space-evenly;
  align-items: center;
  width: 95%;
  
  margin: 20px auto;
  font-family: sans-serif;
  text-align: center;
  @media (max-width: 1100px) {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 200px 200px;
  }
`
const GridItem = styled.div`
  display: grid;

  grid-template-rows: 40px 180px;
  @media (max-width: 1100px) {
    :nth-child(1) {
      grid-area: 1 / 1/ 1 / 5;
    }
    :nth-child(2) {
      grid-area: 2 / 7 / 2 / 11;
    }
    :nth-child(3) {
      grid-area: 1 / 5 / 1 / 9;
    }
    :nth-child(4) {
      grid-area: 1 / 9 / 1 / 13;
    }
    :nth-child(5) {
      grid-area: 2 / 3 / 2 / 7;
    }
  }
  /* @media (max-width: 650px) {
    height: 100%;
    width: 100%;
  } */
  @media (max-width: 550px) {
    :nth-child(1) {
      grid-area: 2 / 7 / 2 / 13;
    }
    :nth-child(2) {
      grid-area: 3 / 7 / 3 / 13;
    }
    :nth-child(3) {
      grid-area: 1 / 3 / 1 / 11;
    }
    :nth-child(4) {
      grid-area: 2 / 1 / 2 / 7;
    }
    :nth-child(5) {
      grid-area: 3 / 1 / 3 / 7;
    }
  }
`
const LinkTitle = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    
  }
`
const Title = styled.div``

const RoundImageStyle = styled(Image)`
  justify-self: center;
  align-self: center;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  &:hover {
    cursor: pointer;
    transform: scale(1.06);
  }
  /* @media (max-width: 500px) {
    width: 100px;
    height: 100px;
  } */
`

const LogoImageStyle = styled(Image)`
  justify-self: center;
  align-self: center;
  width: 250px;

  margin-bottom: 20px;
  &:hover {
    cursor: pointer;
    transform: scale(1.06);
  }
  /* @media (max-width: 500px) {
    width: 180px;
    height: 180px;
    margin-bottom: 50px;
  } */
`

const List = styled.ul``
const ListItem = styled.li`
list-style: none;
  padding: 5px 0;
  text-align: start;
  @media (max-width: 500px) {
    padding: 3px 0;
  }
`
const ListLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`
const JournalLink = styled(Link)`
  text-decoration: none;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
    color:  rgb(159, 66, 178);
  }
`
export default function NavCircles({ images }) {
  const [mediaOpen, setMediaOpen] = useState(false)
  const [aboutOpen, setAboutOpen] = useState(false)
  const [scheduleOpen, setScheduleOpen] = useState(false)

  const toggleAbout = e => {
    e.preventDefault()

    if (e.target.id === "About") {
      setAboutOpen(isOpen => !isOpen)
    } else if (e.target.id === "SNO Media") {
      setMediaOpen(isOpen => !isOpen)
    } else if (e.target.id === "Class Schedule"){
      setScheduleOpen(isOpen => !isOpen)
    }
  }
  const navTitle = [
    "Contact",
    "Class Schedule",
    "About",
    "SNO Media",
    "Meditation",
  ]

  // (condition) ? (true block) : ((condition2) ? (true block2) : (else block2))
  const navLinks = ["contact", "schedule", "about", "media", "meditation"]
  return (
    <Wrapper>
      {navTitle.map((title, i) => {
        var link =
          title === "About" || title === "SNO Media" || title === "Class Schedule" ? "#" : navLinks[i]

        var linkTitle =
          title === "About" || title === "SNO Media" || title === "Class Schedule" ? (
            <LinkTitle to={link} id={title} onClick={toggleAbout}>
              {title}
            </LinkTitle>
          ) : (
            <LinkTitle to={link} id={title}>
              {title}
            </LinkTitle>
          )

        var imageHtml =
          title === "About" ? (
            aboutOpen ? (
              <List>
                <ListItem>
                  <ListLink to="/introduction">Introduction</ListLink>
                </ListItem>
                <ListItem>
                  <ListLink to="/about">About</ListLink>
                </ListItem>
                <ListItem>
                  <JournalLink to="/journal">CATE Journal</JournalLink>
                </ListItem>
              </List>
            ) : (
              <LogoImageStyle
                {...images[i].image}
                alt="Logo Image"
                id={title}
                onClick={toggleAbout}
              />
            )
          ) : title === "SNO Media" ? (
            mediaOpen ? (
              <List>
                <ListItem>
                  <ListLink to="/media">Colorado</ListLink>
                </ListItem>
                <ListItem id="Nevada">
                  <JournalLink href="https://www.sno.org/books-and-mp3s">Nevada</JournalLink>
                </ListItem>
              </List>
            ) : (
              <Link to={link} id={title}>
                <RoundImageStyle
                  {...images[i].image}
                  alt={`${title}`}
                  id={title}
                  onClick={toggleAbout}
                />
              </Link>
            )
          ) : title === "Class Schedule" ? ( 
            scheduleOpen ? (
              <List>
                {/* <ListItem>
                  <ListLink to="/seminar">Summer Seminar 2021</ListLink>
                </ListItem> */}
                <ListItem id="Nevada">
                <ListLink to="/schedule">Weekly SNOC Schedule</ListLink>
                </ListItem>
              </List>
            ) : (
              <Link to={link} id={title}>
                <RoundImageStyle
                  {...images[i].image}
                  alt="Vitvan"
                  id={title}
                  onClick={toggleAbout}
                />
              </Link>
            )
          ) : (
            <Link to={link} id={title}>
              <RoundImageStyle {...images[i].image} alt={`${title}`} />
            </Link>
          )
        return (
          <GridItem key={`${title}-${link}`}>
            <Title>{linkTitle}</Title>
            {imageHtml}
          </GridItem>
        )
      })}
    </Wrapper>
  )
}
