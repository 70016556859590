import * as React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import BlockContent from "@sanity/block-content-to-react"
import "../styles/coverImage.css"
import BackgroundImage from "gatsby-background-image"
import YoutubeBlock from "../components/YoutubeBlock"
import Footer from "../components/Footer"
import NavCircles from "../components/NavCircles"
import "normalize.css"
import GlobalStyles from "../styles/GlobalStyles"
import Typography from "../styles/Typography"

const ContentTitle = styled.h3`
  margin-bottom: 30px;
  letter-spacing: 2.5px;
`
const ContentParagraph = styled.p`
  letter-spacing: 2px;
  font-size: large;
  margin: 10px;
  padding: 10px;
`
const overrides = {
  h3: props => <ContentTitle {...props} />,
  normal: props => <ContentParagraph {...props} />,
}

const serializers = {
  types: {
    block: props =>
      // Check if we have an override for the “style”
      overrides[props.node.style]
        ? // if so, call the function and pass in the children, ignoring
          // the other unnecessary props
          overrides[props.node.style]({ children: props.children })
        : // otherwise, fallback to the provided default with all props
          BlockContent.defaultSerializers.types.block(props),
  },
}

const Wrapper = styled.div``

const Content = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-items: center;
  margin: 60px auto;
  width: 94%;
  @media (max-width: 1100px) {
    display: grid;
  }
`

const IndexPage = ({ data }) => {
  const homepage = data.homepage.nodes[0]

  return (
    <Wrapper>
      <GlobalStyles />
      <Typography />
      <BackgroundImage
        className="cover-image"
        fluid={homepage.coverImage.image.asset.fluid}
        alt="Cover Page"
      >
        <div className="coverOverlay-div">
          <div className="content-box">
            <h1>{homepage.title}</h1>

            <h3 style={{ marginTop: "60px" }}>{homepage.subTitle}</h3>
          </div>
        </div>
      </BackgroundImage>
      <NavCircles images={homepage.navCircles} />
      <Content>
        <BlockContent blocks={homepage._rawBody} serializers={serializers} />
        <YoutubeBlock blocks={homepage.youtube} />
      </Content>
      <Footer />
    </Wrapper>
  )
}
export default IndexPage

export const query = graphql`
  query HomePageQuery {
    homepage: allSanityHomePage {
      nodes {
        title
        subTitle
        _rawBody(resolveReferences: { maxDepth: 10 })
        youtube {
          url
          _type
        }
        coverImage {
          image {
            asset {
              fluid(maxWidth: 2000) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        navCircles {
          image {
            ...ImageWithPreview
          }
        }
      }
    }
  }
`
